<template>
  <div>
    <v-container>
      <v-row dense no-gutters>
        <v-col align="center">
          <h1>404 - Redirecting...</h1>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'FourZeroFour.vue',
  }
</script>

<style lang="scss" scoped>
h1{
  font-size: 3.5rem !important;
}
</style>